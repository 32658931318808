<template>
  <div class="page-header-index-wide">
    <a-card
      :bordered="false"
      :bodyStyle="{ padding: '16px 0', height: '100%' }"
      :style="{ height: '100%' }"
      title="组织机构"
    >
      <a-button slot="extra" @click="treeAddClickHandle()" icon="plus" type="primary">添加机构</a-button>
      <div class="account-settings-info-main">
        <div class="account-settings-info-right">
          <div class="panel">
            <div ref="treeContent">
              <div class="tree-panel">
                <z-tree
                  ref="treeRef"
                  :load-url="treeLoadUrl"
                  @deleteClick="treeDeleteClickHandle"
                  @addClick="treeAddClickHandle"
                  @treeNodeClick="treeNodeClickHandle"
                ></z-tree>
              </div>
            </div>
            <div class="content-panel">
              <div class="detail-content-panel">
                <div class="panel-body">
                  <div class="panel-body-title">
                    <span>组织机构详情</span>
                  </div>
                  <organization-form ref="editRef" @editSuccess="handleEditSuccess"></organization-form>
                </div>
              </div>
            </div>
            <a-modal
              title="组织添加"
              v-model="visible"
              okText="添加"
              cancelText="取消"
              width="960px"
              :confirmLoading="addStatus"
              @ok="handleOk"
            >
              <organization-form
                ref="addRef"
                type="add"
                @addSuccess="handleAddSuccess"
                @addError="handleAddError"
                @addStart="handleAddStart"
              ></organization-form>
            </a-modal>
          </div>
        </div>
      </div>
    </a-card>
  </div>
</template>
<script>
import SERVICE_URLS from '../../../api/service.url'
import ZTree from '../../../components/ztree/index'
import OrganizationForm from './OrganizationForm'

export default {
  name: 'ResourceSystemSetting',
  data() {
    return {
      treeLoadUrl: SERVICE_URLS.organization.tree,
      visible: false,
      addStatus: false,
      currentTreeNode: undefined,
    }
  },
  methods: {
    handleDrop(dropNode, targetNode, moveType) {
      if (dropNode && targetNode && moveType) {
        this.$http(this, {
          url: SERVICE_URLS.organization.moveNode,
          data: {
            dropNodeId: dropNode.id,
            targetNodeId: targetNode.id,
            moveType,
          },
          successTipsContent: '拖拽更新成功',
          success: () => {
            this.$refs.treeRef.loadTree()
          },
        })
      }
    },
    treeNodeClickHandle(treeNode) {
      this.currentTreeNode = treeNode
      this.$refs['editRef'].loadData(treeNode.id)
    },
    treeDeleteClickHandle(treeNode) {
      this.showDeleteConfirm(treeNode)
    },
    treeAddClickHandle(treeNode) {
      if (treeNode) {
        this.currentTreeNode = treeNode
      }
      this.visible = true
      this.$nextTick(() => {
        this.$refs['addRef'].loadSelect(
          this.currentTreeNode.id,
          this.currentTreeNode.attribute === 'unit' ? this.currentTreeNode.id : this.currentTreeNode.unitId
        )
      })
    },
    handleOk() {
      this.$refs['addRef'].submitAdd()
    },
    handleAddStart() {
      this.addStatus = true
    },
    handleEditSuccess(value) {
      this.$refs['treeRef'].updateSelectNode(value)
    },
    handleAddSuccess(value) {
      this.$refs['treeRef'].addNode(value)
      this.visible = false
    },
    handleAddError() {
      this.addStatus = false
    },
    showDeleteConfirm(treeNode) {
      this.$confirm({
        title: '确定删除当前组织机构？',
        content: '会自动删除所有子节点，请谨慎操作！',
        okText: '删除',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          return new Promise((resolve, reject) => {
            this.deleteDelete(treeNode, resolve)
          }).catch(() => this.$error('Oops errors!'))
        },
      })
    },
    deleteDelete(treeNode, resolve) {
      this.$http(this, {
        url: SERVICE_URLS.organization.delete,
        params: {
          id: treeNode.id,
        },
        errorTipsContent: '删除失败，已添加和本组织机构相关的其他业务信息，请联系维护人员，清理业务数据然后删除组织机构，直接删除会带来不必要的麻烦！',
        success: () => {
          this.$refs['treeRef'].deleteNode(treeNode)
          resolve()
        },
        error: () => {
          resolve()
        },
      })
    },
  },
  components: { ZTree, OrganizationForm },
}
</script>
